import {
    CLEAR_PROJECT_OPERATIONAL_INDICATORS,
    GET_GLOBAL_OPERATIONAL_INDICATORS,
    GET_PROJECT_OPERATIONAL_INDICATORS,
    PUT_PROJECT_OPERATIONAL_INDICATORS,
    SET_GLOBAL_OPERATIONAL_INDICATORS,
    SET_PROJECT_OPERATIONAL_INDICATORS,
    TAKE_PROJECT_OPERATIONAL_SNAPSHOT
} from "../../constants";
import { getSelectedOperationalProject } from '../projects';
import { GLOBAL_OPERATIONAL_INDICATORS_ENDPOINT, PROJECT_OPERATIONAL_INDICATORS_ENDPOINT } from "../../constants/endpoint";


export const getGlobalOperationalIndicators = () => {
    // Don't send the request if user is admin. The request will fail.
    const admin = localStorage.getItem("_admin");
    if (Number(admin) === 1) {
        return {
            type: GET_GLOBAL_OPERATIONAL_INDICATORS,
            request: {
                url: GLOBAL_OPERATIONAL_INDICATORS_ENDPOINT,
            },
            meta: {
                onSuccess: (response, requestAction, store) => {
                    store.dispatch(setGlobalOperationalIndicators(response.data));
                    return response;
                }
            }
        };
    }
    return {
        type: null,
        request: null
    };
};

export const getProjectOperationalIndicators = (id) => {
    return {
        type: GET_PROJECT_OPERATIONAL_INDICATORS,
        request: {
            url: `${PROJECT_OPERATIONAL_INDICATORS_ENDPOINT}/${id}`,
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setProjectOperationalIndicators(response.data));
                return response;
            }
        }
    };
};

export const putProjectOperationalIndicators = (id, formData) => {
    return {
        type: PUT_PROJECT_OPERATIONAL_INDICATORS,
        request: {
            url: `${PROJECT_OPERATIONAL_INDICATORS_ENDPOINT}/${id}`,
            method: 'put',
            data: formData
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(getProjectOperationalIndicators(id));
                return response;
            }
        }
    };
};

export const takeProjectOperationalSnapshot = (id,creatorName) => {
    return {
        type: TAKE_PROJECT_OPERATIONAL_SNAPSHOT,
        request: {
            url: `${PROJECT_OPERATIONAL_INDICATORS_ENDPOINT}/takeSnapshot/${id}`,
            method: 'post',
            params: {userFullName: creatorName}

        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(getSelectedOperationalProject(id));
                return response;
            }
        }
    };
};

export const setGlobalOperationalIndicators = (data) => {
    return {
        type: SET_GLOBAL_OPERATIONAL_INDICATORS,
        data: data
    };
};

export const setProjectOperationalIndicators = (data) => {
    return {
        type: SET_PROJECT_OPERATIONAL_INDICATORS,
        data: data
    };
};

export const clearProjectIndicators = () => {
    return {
        type: CLEAR_PROJECT_OPERATIONAL_INDICATORS,
    };
};