export function formatAmount(amount) {
    return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

export function capitalize(str) {
    return `${str.toString().charAt(0).toUpperCase()}${str.toString().slice(1)}`;
}

export const BREADCRUMBS = {
    NAME: {
        url_params: "PARAMS",
        url_search_query: "QUERY",
        default: "DEFAULT"
    }
};

export const roundCharges = (value) =>
{
    return Number(value).toFixed(3).replace(/\.?0+$/, '')
}