import { cloneDeep } from "lodash-es";
import { errorNotification, successNotification } from "../../../../../utils/notification";
import {
    CLEAR_OPERATIONAL_PROJECT_ACTIONS,
    CLEAR_OPERATIONAL_PROJECT_ACTIONS_PAGINATION,
    CLEAR_SELECTED_OPERATIONAL_PROJECT_ACTION,
    DELETE_OPERATIONAL_PROJECT_ACTION,
    DELETE_MULTIPLE_OPERATIONAL_PROJECT_ACTIONS,
    GET_OPERATIONAL_PROJECT_ACTIONS,
    POST_OPERATIONAL_PROJECT_ACTION,
    PUT_OPERATIONAL_PROJECT_ACTION,
    SET_OPERATIONAL_PROJECT_ACTIONS,
    SET_OPERATIONAL_PROJECT_ACTIONS_PAGINATION,
    SET_SELECTED_OPERATIONAL_PROJECT_ACTION,
    EXPORT_OPERATIONAL_PROJECT_ACTIONS,
    GET_SELECTED_OPERATIONAL_PROJECT_ACTION,
    SET_OPERATIONAL_PROJECT_ACTIONS_SORT,
    CLEAR_OPERATIONAL_PROJECT_ACTIONS_SORT,
    SET_OPERATIONAL_PROJECT_ACTIONS_FILTER,
    CLEAR_OPERATIONAL_PROJECT_ACTIONS_FILTER,
    CLEAR_SELECTED_OPERATIONAL_PROJECT,
    POST_OPERATIONAL_RISK_ACTION,
    SET_OPERATIONAL_RISK_ACTIONS, DELETE_OPERATIONAL_RISK_ACTION, PUT_OPERATIONAL_RISK_ACTION
} from "../../constants";
import { OPERATIONAL_PROJECT_ACTIONS_ENDPOINT } from "../../constants/endpoint";
import ActionFilters from "../../../components/Actions/components/ActionFilters";
import { getOperationalProjectRisks } from "../risks";
import { setIsError500 } from "../../../../../App/appSlice";
import Swal from "sweetalert2";
import { updateIsError500 } from "../../../../../utils/updateIsError500";

const adjustFilter = (filter) => {
    let f = cloneDeep(filter);
    Object.keys(f).forEach(k => {
        if(f[k] === "" || f[k] === null || f[k] === undefined || (Array.isArray(f[k]) && f[k].length === 0) || (typeof f[k] === "object" && Object.keys(f[k]).length === 0)){
            delete f[k];
        }else{
            switch(k){
                case 'priorities':
                    f['priorities'] = f[k].join(',');
                    break;
                case 'status':
                    f['status'] = f[k].join(',');
                    break;
                case 'projects':
                    f['projectIds'] = f[k].map(p => p.id).join(',');
                    delete f[k];
                    break;
                case 'types':
                    f['types'] = f[k].join(',');
                    break;
                case 'responsables':
                    f['responsableIds'] = f[k].map(r => r.id).join(',');
                    delete f[k];
                    break;
                default:
                    break;
            }
        }
    });

    return f;
};

export const getOperationalProjectActions = (projectId) => {
    return {
        type: GET_OPERATIONAL_PROJECT_ACTIONS,
        request: {
            url: OPERATIONAL_PROJECT_ACTIONS_ENDPOINT
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const pagination = state.operational.actions.config.pagination;
                const sort = state.operational.actions.list.sort;
                const filter = adjustFilter(state.operational.actions.list.filter);
                if(filter.search){
                    request.url = `${request.url}/search?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}`;
                    request.params = {
                        name: filter.search
                    };
                }else{
                    request.url = `${request.url}?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}`;
                    request.params = filter;
                }

                if(projectId){
                    request.params = {...request.params, projectIds: projectId , projectId:projectId};
                }

                return request;
            },
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setOperationalProjectActions(response.data));
                return response;
            }
        }
    };
};

export const exportOperationalProjectActions = (projectId) => {
    return {
        type: EXPORT_OPERATIONAL_PROJECT_ACTIONS,
        request: {
            url: `${OPERATIONAL_PROJECT_ACTIONS_ENDPOINT}/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const filter = adjustFilter(state.operational.actions.list.filter);
    
                request.params = filter;
    
                if(projectId) {
                    request.params.projectIds = projectId;
                }
                
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                return response.data;
            }
        }
    };
};

export const getSelectedOperationalProjectAction = (id,type) => {
    return {
        type: GET_SELECTED_OPERATIONAL_PROJECT_ACTION,
        request: {
            url: `${OPERATIONAL_PROJECT_ACTIONS_ENDPOINT}/${id}?type=${type || "project"}`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setSelectedOperationalProjectAction(response.data));
                return response;
            },
            onError: (error, requestAction, store) => {
                updateIsError500(error, store)
            }
        }
    };
};

export const postOperationalProjectAction = (action, global) => {
    const projectId = action.project.id;
    return {
        type: POST_OPERATIONAL_PROJECT_ACTION,
        request: {
            url: OPERATIONAL_PROJECT_ACTIONS_ENDPOINT,
            method: 'post',
            params: {projectId : projectId},
            data: action
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getOperationalProjectActions(global ? null : projectId));
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const deleteOperationalProjectAction = (actionId, projectId, actionInRiskPopUp) => {
    return {
        type: DELETE_OPERATIONAL_PROJECT_ACTION,
        request: {
            url: `${OPERATIONAL_PROJECT_ACTIONS_ENDPOINT}/${actionId}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                if(actionInRiskPopUp)
                    store.dispatch(getOperationalProjectRisks(projectId));
                else
                    store.dispatch(getOperationalProjectActions(projectId));
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "delete_error");
                throw error;
            }
        }
    };
};

export const putOperationalProjectAction = (action, global) => {
    const projectId = global ? null : action.project.id;
    return {
        type: PUT_OPERATIONAL_PROJECT_ACTION,
        request: {
            url: `${OPERATIONAL_PROJECT_ACTIONS_ENDPOINT}/${action.id}`,
            method: 'put',
            data: action
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getOperationalProjectActions(projectId));
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteMultipleOperationalProjectActions = (selection, projectId) => {
    return {
        type: DELETE_MULTIPLE_OPERATIONAL_PROJECT_ACTIONS,
        request: {
            url: `${OPERATIONAL_PROJECT_ACTIONS_ENDPOINT}`,
            method: 'delete',
            params: { selection: selection.join(",") }
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getOperationalProjectActions(projectId));
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "delete_error");
                throw error;
            }
        }
    };
};

export const setOperationalProjectActions = (data) => {
    return {
        type: SET_OPERATIONAL_PROJECT_ACTIONS,
        payload: data
    };
};

export const setSelectedOperationalProjectAction = (data) => {
    return {
        type: SET_SELECTED_OPERATIONAL_PROJECT_ACTION,
        payload: data
    };
};

export const setOperationalProjectActionsPagination = (pagination) => {
    return {
        type: SET_OPERATIONAL_PROJECT_ACTIONS_PAGINATION,
        payload: pagination
    };
};

export const clearOperationalProjectActions = () => {
    return {
        type: CLEAR_OPERATIONAL_PROJECT_ACTIONS
    };
};

export const clearSelectedOperationalProjectAction = () => {
    return {
        type: CLEAR_SELECTED_OPERATIONAL_PROJECT_ACTION
    };
};

export const clearOperationalProjectActionsPagination = () => {
    return {
        type: CLEAR_OPERATIONAL_PROJECT_ACTIONS_PAGINATION
    };
};

export const setOperationalProjectActionsSort = (sort) => {
    return {
        type: SET_OPERATIONAL_PROJECT_ACTIONS_SORT,
        payload: sort
    };
};

export const clearOperationalProjectActionsSort = () => {
    return {
        type: CLEAR_OPERATIONAL_PROJECT_ACTIONS_SORT
    };
};

export const setOperationalProjectActionsFilter = (filter) => {
    return {
        type: SET_OPERATIONAL_PROJECT_ACTIONS_FILTER,
        payload: filter
    };
};

export const clearOperationalProjectActionsFilter = () => {
    return {
        type: CLEAR_OPERATIONAL_PROJECT_ACTIONS_FILTER
    };
};


export const setOperationalRiskActions = (data) => {
    return {
        type: SET_OPERATIONAL_RISK_ACTIONS,
        payload: data
    };
};

export const getOperationalRiskActions = (riskId) => {
    return {
        type: GET_OPERATIONAL_PROJECT_ACTIONS,
        request: {
            url: OPERATIONAL_PROJECT_ACTIONS_ENDPOINT
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const pagination = state.operational.actions.config.pagination;
                const sort = state.operational.actions.list.sort;
                if(riskId){
                    request.url = `${request.url}/search?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}`;
                    request.params = {
                        riskId : riskId
                    };
                }
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setOperationalRiskActions(response.data));
                return response;
            }
        }
    };
}
export const postOperationalRiskAction = (action,riskId) => {
    return {
        type: POST_OPERATIONAL_RISK_ACTION,
        request: {
            url: OPERATIONAL_PROJECT_ACTIONS_ENDPOINT,
            method: 'post',
            params:{riskId:riskId},
            data: action
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const deleteOperationalRiskAction = (actionId, riskId) => {
    return {
        type: DELETE_OPERATIONAL_RISK_ACTION,
        request: {
            url: `${OPERATIONAL_PROJECT_ACTIONS_ENDPOINT}/${actionId}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "delete_error");
                throw error;
            }
        }
    };
};

export const putOperationalRiskAction = (action, global) => {
    const actionId = action.id;
    const riskId = action.risk.id;
    return {
        type: PUT_OPERATIONAL_RISK_ACTION,
        request: {
            url: `${OPERATIONAL_PROJECT_ACTIONS_ENDPOINT}/${actionId}`,
            method: 'put',
            data: action
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};
