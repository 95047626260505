export default {
    unauthorized_access_title: "Accès non autorisé",
    you_are_not_authorized_to_access: "Vous n'avez pas le droit d'accèder à cette ressource.",
    network_error: "Erreur du réseau",
    network_error_text: "Impossible de se connecter au serveur, vérifiez votre connexion Internet ou réessayez plus tard.",
    error_alert_title: "Une erreur est survenue",
    error_alert_text: "Une erreur inattendue s'est produite lors de votre demande. Veuillez réessayer plus tard.",
    token_expired_alert_title: "Session expirée",
    token_expired_alert_text: "Vote session a expiré. Veuillez vous re-connecter SVP...",
    service_error_text: "Le service est temporairement indisponible. Veuillez réessayer ultérieurement.",
    Copyright: "Tous droits réservés",
    settings: "Paramètres",
    administration: "Administration",
    ok: "Ok",
    my_novy_account: "Mon compte Novy",
    logout: "Se déconnecter",
    no_notifications: "Pas de notifications",
    operational_view: "Vue Opérationnelle",
    financial_view: "Vue Financière",
    sort_by: "Trier par",
    sorted_by: "Trié par",
    projects_count_suffix: "Projets",
    select_interval: "Selectionner un intervalle",
    cancel:"Annuler",
    confirm:"Confirmer",
    enregistrement: "Enregistrement",
    take_snapshot: "Figer le dashboard",
    save_dashboard_indicators_question: "Voulez-vous vraiment figer les indicateurs de ce Dashboard ?",
    dashboard_fige_success: "Votre Dashboard a été figé avec succès",
    dashboard_fige: "Dashboard Figé",
    search_placeholder: "Recherche",
    modification: 'Modification',
    edit_dashboard_indicators_question: "Voulez-vous vraiement modifier les indicateurs de ce Dashboard ?",
    dashboard_modifie_success: 'Votre Dashboard a été modifié avec succès',
    annulation: 'Annulation',
    cancel_edit_dashboard_indicators_question: 'Voulez-vous vraiment annuler les modifications de ce Dashboard',
    dashboard_modifie: 'Dashboard Modifié',
    raf: "RAF",
    date: "Date",
    risques: "Risques",
    actions: "Actions",
    en_cours_status: "En cours",
    termine_status: "Terminé",
    forfait: "Forfait",
    regie: "Régie",
    project_col: "Projet",
    ch_vendue_col: "Ch. vendue",
    ch_consommee_col: "Ch. consommée",
    raf_col: "RAF",
    risque_col: "Risque",
    type_col: "Type",
    status_col: "Statut",
    category_col: "Catégorie",
    risk_category: "Catégorie de risque",
    risk_description:"Description de risque",
    action_description:"Description d'action",
    projects_list_page_title: "Vue d'ensemble des projets",
    filter_all_choice: "Tous",
    suppression: "Suppression",
    notes: "Notes",
    delete:"Supprimer",
    save: "Enregistrer",
    yes_save: "Oui, enregistrer",
    no: "Non",
    edit: "Modifier",
    project_indicators: "Indicateurs de projet",
    data_history: "Historique des données",
    save_quick_edit: "Enregistrer la modification",
    quick_edit: "Modification rapide",
    export: "Exporter",
    export_reports: "Exporter les rapports",
    synthese: "Synthèse",
    historique: "Historique",
    projects_list: "Liste des projets",
    project: "Projet",
    create_success: "Création effectuée avec succès",
    update_success: "Modification effectuée avec succès",
    delete_success: "Suppression effectuée avec succès",
    create_error: "Création a échoué",
    update_error: "Modification a échoué",
    delete_error: "Suppression a échoué",
    no_data: "Aucun enregistrement trouvé",
    no_project_found: "Aucun projet trouvé",
    pagination_of: "sur",
    update: "Mettre à jour",
    reprendre: "Reprendre",
    resume_live_dashboard: "Quitter l'historique",
    snapshot: 'Snapshot',
    rtables: 'Tables de référence',
    parameters: "Paramètres",
    project_risk: 'Risque de projet',
    project_construction: 'Construction de projet',
    project_perimetre: 'Périmètre de projet',
    project_planning: 'Planning de projet',
    project_staffing: 'Staffing de projet',
    action_priority: "Priorité d'action",
    action_status: "Statut d'action",
    label: "Libellé",
    threshold: "Seuil",
    level: "Niveau",
    description: "Description",
    name: "Nom",
    value: "Valeur",
    add: "Ajouter",
    confirm_delete: "Êtes-vous sûr(e) de vouloir supprimer",
    start: "Début",
    end: "Fin",
    client_col: "Client",
    no_client_found: "Aucun client trouvé",
    include_sub_projects_switch_title: "Inclure les sous projets",
    client: "Client",
    clients: "Clients",
    assign_client: "Attacher un client",
    notify_actions: "Envoyer les rappels",
    the_language: "La langue",
    not_yet_implemented: "n'est pas encore implémentée",
    parent_project: "Projet parent",
    last_activity: "Dernière activité",
    affect_in_novy: "Affecter sur Novy",
    export_as_pdf: "Exporter en PDF",
    screen_size_not_supported_title: "Résolution Non Supportée",
    screen_size_not_supported_description1: "La taille de votre écran n'est pas supportée.",
    screen_size_not_supported_description2: "Veuillez ouvrir la page dans une écran large (≥960px).",
    open: "Ouvrir",
    clear: "Effacer",
    close: "Fermer",
    variables: "Variables",
    tva_rate: "Taux TVA",
    default_value: "Valeur par défaut",
    number: "Numéro",
    number2: "Nombre",
    percent: "Pourcentage",
    view_as_pdf: "Consulter en PDF",
    today: "Aujourd'hui",
    pdf_not_available: "PDF non disponible",
    add_to_favorites: "Ajouter aux favoris",
    remove_from_favorites: "Supprimer des favoris",
    automated_snapshot: "Snapshot automatique",
    first_page: "Première page",
    last_page: "Dernière page",
    previous_page: "Page précédente",
    next_page: "Page suivante",
    labelRowsPerPage: "Lignes par page:",
    January: "Janvier",
    February: "Février",
    March: "Mars",
    April: "Avril",
    May: "Mai",
    June: "Juin",
    July: "Juillet",
    August: "Août",
    September: "Septembre",
    October: "Octobre",
    November: "Novembre",
    December: "Décembre",
    january: "janvier",
    february: "février",
    march: "mars",
    april: "avril",
    may: "mai",
    june: "juin",
    july: "juillet",
    august: "août",
    september: "septembre",
    october: "octobre",
    november: "novembre",
    december: "décembre",
    grid_view: "Vue en grille",
    list_view: "Vue en liste",
    category_view:"Vue en catégories",
    kanban_view:"Vue en kanban",
    view_all: "Voir tous",
    view_favorites: "Voir mes favoris",
    show_more: "Afficher Plus",
    all: "Tous",
    projects: "Projets",
    favorites: "Favoris",
    my_favorites: "Mes favoris",
    alpha_version: "Cette version est encore en période d'essai et vise à tester l'expérience utilisateur. Une amélioration supplémentaire peut impliquer des changements majeurs de la fonctionnalité ou une réinitialisation des données.",
    responsable: "Responsable",
    reset: "Réinitialiser",
    drag_and_drop_file: "Glissez et déposez votre fichier ici",
    or: "Ou",
    browse: "Parcourir",
    kilo_byte_abbr: "ko",
    no_file_selected: "Aucun fichier choisi",
    processing: "Traitement en cours",
    import: 'Importer',
    header_row: "Ligne de l'entête",
    first_data_row: "1ère ligne de données",
    rate: "Taux",
    invalid_file_or_misconfiguration_error: 'Une erreur est servenue. Veuillez vérifier votre fichier et les paramètres du chargement puis réessayer.',
    download_template: "Télécharger le modèle de base",
    template: "Modèle",
    my_settings: "Mes paramètres",
    receive_action_reminders: "Recevoir les rappels des actions",
    action_reminder_days: "Les jours des rappels",
    no_items_found: "Aucun élément trouvé",
    no_risk_found: "Aucun risque trouvé",
    no_type_found: "Aucun type trouvé",
    no_status_found: "Aucun statut trouvé",
    no_probability_found: "Aucune probabilité trouvée",
    session_expired_title: "Session expirée",
    session_expired_details: "Vous devez actualiser la page afin de rafraichir votre session, votre saisie sera perdue.",
    refresh: "Actualiser",
    access_control: "Gestion d'accès",
    access_control_by_client: "Gestion d'accès par client",
    confirm_delete_access_control: "Êtes-vous sûr(e) de vouloir supprimer l'entrée de gestion d'accès pour {{collaboratorName}} ?",
    available_clients: "Clients disponibles",
    assigned_clients: "Clients assignés",
    available_projects: "Projets disponibles",
    assigned_projects: "Projets assignés",
    no_collaborator_is_selected: "Aucun collaborateur est sélectionné",
    client_already_assigned: "Client {{name}} déjà affecté",
    parent_project_already_assigned: "Projet parent {{name}} déjà affecté",
    System: "Système",
    Information:'Information',
    priority:'Priorité',
    probability: 'Probabilité',
    status:'Statut',
    category:'catégories',
    inList:' Dans la liste ',
    viewKanban : 'Tableau de Kanban',
    risk_priority: "Priorité de risque",
    risk_probability: "Probabilité de risque",
    risk_status: "Statut de risque",
    delete_action_type_error: "Impossible de supprimer ce type d'action. Il est actuellement associé à une action existante",
    delete_action_priority_error: "Impossible de supprimer cette priorité d'action. Elle est actuellement associée à une action existante",
    delete_action_status_error: "Impossible de supprimer ce statut d'action. Il est actuellement associé à une action existante",
    delete_risk_probability_error: "Impossible de supprimer cette probabilité de risque. Elle est actuellement associée à un risque existant",
    delete_entity_agency_error: "Impossible de supprimer cette entité. Elle est actuellement associée à une agence ou un profil de coût existant",
    delete_risk_priority_error: "Impossible de supprimer cette priorité de risque. Elle est actuellement associée à un risque existant",
    delete_risk_category_error: "Impossible de supprimer cette catégorie de risque. Elle est actuellement associée à un risque existant",
    delete_risk_status_error: "Impossible de supprimer ce statut de risque. Il est actuellement associé à un risque existant",
    delete_client_relation_error: "Impossible de supprimer la donnée de relation client. Elle est actuellement associée à un projet en cours",
    delete_project_staffing_error: "Impossible de supprimer la donnée de personnel du projet. Elle est actuellement associée à un projet en cours",
    delete_project_planning_error: "Impossible de supprimer la donnée de planification du projet. Elle est actuellement associée à un projet en cours",
    delete_project_perimetre_error: "Impossible de supprimer ce périmètre du projet. Il est actuellement associé à un projet en cours",
    delete_project_construction_error: "Impossible de supprimer la donnée de construction du projet. Elle est actuellement associée à un projet en cours",
    delete_solution_maitrise_error: "Impossible de supprimer la donnée de maitrise de la solution. Elle est actuellement associée à un projet en cours",
    delete_project_risk_error: "Impossible de supprimer ce risque. Il est actuellement associé à un projet en cours",
    delete_cost_profile_error: "Impossible de supprimer ce profil de coût. Il est actuellement associé à un collaborateur",
    add_comment_to_action : "Ajouter un commentaire à l'action",
    add_comment_to_risk : "Ajouter un commentaire au risque",
    comment_label : "commentaire",
    add_comment : "Ajouter un commentaire",
    close_comment : "Commentaire de clôture",
    financial_Monitoring_History: "Historique du suivi financier",
    operational_Monitoring_History: "Historique du suivi opérationnel",
    monthly_cost_col : "Cout Mensuel",
    cost_profile_col : "Profil de coûts",
    collaborators_list_page_title: "Vue d'ensemble des collaborateurs",
    collaborators_count_suffix: "collaborateurs",
    lower_cost_Threshold:"Seuil inférieur",
    upper_cost_Threshold:"Seuil supérieur",
    delete_risks_that_have_actions_modal:"Vous êtes sur le point de supprimer des risques qui sont associés à des actions",
    chartTitle_coutConsomme : "Coût engagé",
    chartTitle_coutActualise : "Coût actualisé",
    chartTitle_caProduit : "Ca produit",
    chartTitle_caFacture : "Ca facturé",
    fn_evolution_chart_title : "Évolution Coût engagé / Coût actualisé / Ca produit / Ca facturé",
    download: "Télécharger le modèle",
    number_of_categories_text : "Le nombre des risques associés à cette catégorie est ",
    import_title : "Importer la table de réference",
    indicators_history_title: "Historique des indicateurs",
    tjm_col: "tjm",
    entity_agency: "Entité d'agences",
    entity: "Entité",
    activeProfile: "Actif",
    inactiveProfile: "Inactif",
    state: "Etat",
    agencies: "Agences"
};
